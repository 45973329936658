
import {defineComponent, ref} from "vue";
import {dateConvert} from "@/core/filters/datatime";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import AddTransferOperationModal from "@/components/operations/transfers/transferoperations/AddTransferOperationModal.vue";
import {read, utils, writeFileXLSX } from "xlsx";

export default defineComponent({

    components: {
        AddTransferOperationModal,
    },
    setup() {

        const refComp_AddTransferOperationModal = ref<null>();

        let arrGroupedTransfers = ref ([]);
        let arrTransfers = ref([]);
        let arrCars = ref([]);
        let ccvOperationDate;

        function initComp(prmStartDate, prmCars){
            console.log("TransferList initComp");
            arrCars.value = prmCars.value;
            (refComp_AddTransferOperationModal.value as any).initComp(arrCars);
            sendFilterTransfers(prmStartDate);
        }

        function setOperationDate(prmOperationDate) {
            ccvOperationDate = prmOperationDate;
            console.log("setOperationDate "+ccvOperationDate);
        }

        function sendFilterTransfers(prmStartDate) {
            console.log("sendFilterTransfers START prmStartDate: "+prmStartDate);
            arrGroupedTransfers.value = [];
            arrTransfers.value = [];
            let vvPayload = {is_active: "active", ProductType: "TRANSFER", StartDate:dateConvert.method.convertToDateFormated(prmStartDate, "YYYY-MM-DD")};
            console.log("sendFilterTransfers vvPayload: "+JSON.stringify(vvPayload));
            store.dispatch(Actions.RESERVATION_PRODUCTS_OPERATION_TRANSFER_LIST, vvPayload).then((prmData) => {
                //console.log(prmData);
                if (prmData.data!==undefined) {
                    arrTransfers.value = prmData.data;
                    setGroupedTransfers();
                }
            });
        }

        function setGroupedTransfers() {
            arrGroupedTransfers.value = [];
            let arr1 = [];
            let arr2 = [];
            for (let i=0; i<arrTransfers.value.length; i++) {
                let eachTransfer = arrTransfers.value[i] as any;
                if (eachTransfer.RemarksVC3==="ARRIVAL") {
                    arr1.push(eachTransfer);
                }
                if (eachTransfer.RemarksVC3==="RETURN") {
                    arr2.push(eachTransfer);
                }
            }
            arrGroupedTransfers.value.push(arr1);
            arrGroupedTransfers.value.push(arr2);
        }

        function click_SelectTranfer(prmTransfer) {
            (refComp_AddTransferOperationModal.value as any).showComp(prmTransfer, ccvOperationDate);
        }

        function getGroupHeader(prmIndex) {
            if (prmIndex===0) {
                return "Gelen Yolcular";
            }
            return "Dönen Yolcular";
        }

        function click_ExportToExcel() {
            exportToExcel();
        }

        function exportToExcel() {
            const ws1 = utils.json_to_sheet(convertExcelSheet(arrGroupedTransfers.value[0]));
            const ws2 = utils.json_to_sheet(convertExcelSheet(arrGroupedTransfers.value[1]));
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws1, "Geliş");
            utils.book_append_sheet(wb, ws2, "Dönüş");
            writeFileXLSX(wb, "List.xlsx");
        }

        function convertExcelSheet(prmTransfers) {
            let rv=[];
            for (let i=0; i<prmTransfers.length; i++) {
                let eachTransfer = prmTransfers[i];
                let vvNew = {
                    "Yolcu": libTools.method.getFirstPaxNameSurname(libTools.method.getRemarksJSONPaxes(eachTransfer.RemarksJSON1)),
                    "Acente": eachTransfer.AccountTitle,
                    "Departman": "",
                    "Tür": libTools.method.getParsedObj(eachTransfer.RemarksJSON3).VIPType,
                    "Nereden": libTools.method.getParsedObj(eachTransfer.RemarksJSON3).TransferLocationAName,
                    "Nereye": libTools.method.getParsedObj(eachTransfer.RemarksJSON3).TransferLocationBName,
                    "UçuşNo": libTools.method.getRemarksJSONFlightTimes(eachTransfer.RemarksJSON3).FlightNo,
                    "Uçak Kalkış Saati": libTools.method.getRemarksJSONFlightTimes(eachTransfer.RemarksJSON3).FlightDepTime,
                    "Uçak Varıış Saati": libTools.method.getRemarksJSONFlightTimes(eachTransfer.RemarksJSON3).FlightArrTime,
                    "Transfer Tarihi": dateConvert.method.convertToDateFormated(eachTransfer.StartDate, "DD-MM-YYYY"),
                    "Transfer Saati": libTools.method.getParsedObj(eachTransfer.RemarksJSON3).TransferTime,
                    "Araç Plakası": eachTransfer.PlateNumber,
                    "Araç Şirketi": eachTransfer.TransferCompanyTitle,
                    "Araç Tipi": libTools.method.getValueOrEmptyString(eachTransfer.TransferCarType) + " " + libTools.method.getValueOrEmptyString(eachTransfer.TransferCarTitle),
                };
                rv.push(vvNew);
            }
            return rv;
        }

        return {
            libTools, dateConvert,
            initComp,
            arrGroupedTransfers, arrTransfers,
            sendFilterTransfers,
            refComp_AddTransferOperationModal,
            click_SelectTranfer,
            setOperationDate,
            getGroupHeader,
            click_ExportToExcel,
        };
    },
});
