
import {computed, defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import store from "@/store";
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';
import {Turkish} from "flatpickr/dist/l10n/tr"
import {dateConvert} from "@/core/filters/datatime";
import {Actions} from "@/store/enums/StoreEnums";
import TransferList from "@/components/operations/transfers/transferoperations/TransferList.vue";


export default defineComponent({
    name: "operation-transfer",
    components: {
        TransferList,
    },

    setup() {

        const refComp_TransferList = ref<null>();


        let fpickStartDate;

        let arrCars = ref([]);

        onMounted(() => {
            setCurrentPageBreadcrumbs("Transfer", ["Operasyon"]);
            setDatePicker();
            store.dispatch(Actions.TRANSFER_CARS_LIST, {}).then((prmPayload)=>{
                //console.log("Transfer Operation TRANSFER_CARS_LIST");
                //console.log(prmPayload);
                arrCars.value = prmPayload.data;

                (refComp_TransferList.value as any).initComp((fpickStartDate as any).selectedDates[0], arrCars);
            });
        });

        function setDatePicker() {
            let transferDate = {"locale": Turkish, dateFormat: "d-m-Y (D)", defaultDate: 'today',};
            fpickStartDate = flatpickr("#inpTransferOperation_TransferDate", transferDate);
            (fpickStartDate as any).config.onChange.push(changeFlatPicker_TransferDate);
            changeFlatPicker_TransferDate();
        }

        function changeFlatPicker_TransferDate() {
            (refComp_TransferList.value as any).setOperationDate((fpickStartDate as any).selectedDates[0]);
        }

        function click_FilterTransfer() {
            (refComp_TransferList.value as any).sendFilterTransfers((fpickStartDate as any).selectedDates[0]);
        }

        return {
            dateConvert, Turkish,
            refComp_TransferList,
            click_FilterTransfer,
        };
    },
});
